import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { handleOverflow } from '../utils/helpers';

const encoded_hash = '%23';

/**
 * Then moving to Hessel.dk we should move <UiProvider> to _app.tsx
 * so we can use it in all pages
 * Now, we call on handleOverflow(false) directly rather than using the UiProvider
 */
export function useUrlHandler(): void {
    const router = useRouter();
    const previousPathRef = useRef<string>();

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            const newPath = url.split('?')[0];

            if (newPath !== previousPathRef.current) {
                handleOverflow(false);
                previousPathRef.current = newPath;
            }

            if (!url.includes(encoded_hash) && !window.location.hash) {
                return;
            }

            if (url.includes(encoded_hash) && !url.includes('/m/')) {
                router.replace(url.replace(encoded_hash, '#'));
            }

            const hash = window.location.hash;

            if (hash && hash.length > 1) {
                const id = hash.substring(1);
                const element = document.querySelectorAll(`[id="${id}" i]`);

                if (element && element.length > 0) {
                    element[0].scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                }
            }
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router]);
}
